import {BOIReportType} from "@/constants/FinCENConstants";
import FileMetaDTO from "@/dto/files/FileMetaDTO";
import NewInvoiceDTO from "@/dto/fincen/NewInvoiceDTO";

export class AddBOIReportPayload {

    companyId?: number;
    type?: BOIReportType;
    filingDate?: Date;
    filedByCustomer = false;
    paid = true;
    invoice: NewInvoiceDTO | null = new NewInvoiceDTO();
    reportFileId?: FileMetaDTO;
    correctedReportId?: number;

}
